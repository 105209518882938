<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col sm="12" lg="4">
          <h3 class="mb-0 title">
            Users

            <button
              class="btn btn-sm btn-primary add-user-sm"
              @click="showModal()"
            >
              <span><i class="fas fa-plus"></i></span>
            </button>
          </h3>
        </b-col>
        <b-col sm="8" lg="4">
          <b-form-group
            id="search-wrapper"
            label-for="input-1"
            v-bind:class="{ active: isActive, focused: isFocused }"
          >
            <b-form-input
              id="filter-input"
              ref="filterinput"
              type="text"
              placeholder="Search Users"
              @keyup="filterTableBySearch"
              @blur.native="removeCancelSearchIcon"
              @focus="focused"
            ></b-form-input>
            <span class="clear-search" @click="cancelFilterBySearch()">
              <i class="fas fa-times"></i>
            </span>
          </b-form-group>
        </b-col>
        <b-col class="text-right add-user-ds" md="4" lg="4">
          <button class="btn btn-sm btn-primary" @click="showModal()">
            Add User
          </button>
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>
    <!-- Filter -->
    <el-table
      class="table-responsive table users"
      :data="displayData"
      header-row-class-name="thead-light"
      v-if="!isLoading"
      @sort-change="sortChange"
    >
      <el-table-column label="Fullname" min-width="120px" prop="Name" sortable>
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.Name }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Email Address"
        min-width="160px"
        prop="EmailAddress"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.EmailAddress }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Phone Number"
        min-width="160px"
        prop="phoneNumber"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.phoneNumber }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Branch"
        min-width="160px"
        prop="branchName"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.branchName }}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Supplier" min-width="160px" prop="Supplier" sortable>
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.EmailAddress }}</div>
        </template>
      </el-table-column> -->

      <el-table-column label="Actions" min-width="60px">
        <template v-slot="{ row }">
          <div class="row-buttons">
            <button
              class="btn btn-sm btn-primary"
              :data-id="row.Id"
              @click="showUserEditModal(row.Id)"
            >
              <span class="button-text">Edit</span>
              <span class="button-icon"><i class="fas fa-pen"></i></span>
            </button>
            <button
              class="btn btn-sm btn-primary"
              :data-id="row.Id"
              @click="deleteUser(row.Id)"
            >
              <span class="button-text">Delete</span>
              <span class="button-icon"><i class="far fa-trash-alt"></i></span>
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
    >
    </el-pagination>

    <!-- Delete user table -->
    <modal :show="deleteUserModal">
      <div class="form delete-user">
        <div>
          <div class="message delete">
            <div class="message-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <span class=""
              >Deleting this account will permanently remove all user
              data.</span
            >
          </div>
          <div class="success-message" v-if="loading">
            <spinner v-if="loading"></spinner>
          </div>
        </div>
        <div class="buttons">
          <b-button @click="confirmDeleteUser" type="submit" variant="primary"
            >Confirm</b-button
          >
          <b-button
            @click="closeModalHandler"
            type="button"
            variant="primary cancel"
            >Cancel</b-button
          >
        </div>
      </div>
    </modal>

    <!-- Add User -->
    <modal
      :show="isModalVisible"
      @close="closeModal"
      :hide-footer="true"
      class="user-modal"
    >
      <b-form
        class="form"
        @submit.prevent="onSubmit"
        v-if="!loading && success == null && error == null"
      >
        <b-form-group id="input-group-1" label="Full Name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.Name"
            type="text"
            placeholder="Enter Full Name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Email Address:"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.EmailAddress"
            type="email"
            placeholder="Enter Email Adress"
            autocomplete="off"
            required
            readonly
            onfocus="this.removeAttribute('readonly');"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Phone Number:"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.phoneNumber"
            type="text"
            placeholder="Enter Phone Number"
            autocomplete="off"
            required
            readonly
            onfocus="this.removeAttribute('readonly');"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" label="Password:" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="form.Password"
            type="password"
            placeholder="Enter Password"
            autocomplete="off"
            required
            readonly
            onfocus="this.removeAttribute('readonly');"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Select branch:"
          label-for="input-4"
          v-if="supplierIDs.length == 0"
        >
          <b-form-select
            v-model="form.branchId"
            :options="branches"
            :value="branches[0].Id"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Select supplier(s):"
          label-for="input-4"
          v-if="form.branchId == ''"
        >
          <b-form-select
            v-model="selected"
            :options="suppliers"
            @change="selectedDistributor($event)"
          >
          </b-form-select>
        </b-form-group>
        <!-- Selected distributors -->
        <div class="selected-data add-user" v-if="!updatingUser">
          <div class="periods">
            <span
              v-for="supplier in selectedSuppliers"
              :key="supplier.Name"
              :title="supplier.Name"
            >
              <a href="#" class="supplier-name">{{ supplier.Name }}</a
              ><span class="remove" @click="removeReportPeriod(supplier.Id)"
                ><i class="far fa-trash-alt"></i
              ></span>
            </span>
          </div>
        </div>
        <b-button type="submit" variant="primary">{{
          !updatingUser ? "Add user" : "Update User"
        }}</b-button>
        <b-button
          @click="closeModalHandler"
          type="button"
          variant="primary cancel"
          >Cancel</b-button
        >
      </b-form>
      <div class="success-message" v-if="loading">
        <spinner v-if="loading"></spinner>
      </div>
      <div class="success-message" v-if="success">
        <div class="icon-wrapper">
          <i class="fas fa-check"></i>
        </div>
        <p>User added successfully!</p>
      </div>
      <div class="success-message error" v-if="error != null">
        <div class="message-icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <p>{{ error }}</p>
        <b-button @click="closeModalHandler" type="button" variant="primary"
          >Close</b-button
        >
      </div>
    </modal>

    <!-- Edit User -->

    <modal
      :show="editUserModal"
      @close="closeModal"
      :hide-footer="true"
      class="user-modal"
    >
      <b-form
        class="form"
        @submit.prevent="onEditUserSubmit"
        v-if="!loading && success == null && editState == false"
      >
        <b-form-group id="input-group-1" label="Full Name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.Name"
            type="text"
            placeholder="Enter Full Name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Email Address:"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.EmailAddress"
            type="email"
            placeholder="Enter Email Adress"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Phone Number:"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.phoneNumber"
            type="text"
            placeholder="Enter Phone Number"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Password:" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="form.Password"
            type="password"
            placeholder="Enter Password"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Select branch:"
          label-for="input-4"
          v-if="supplierIDs.length == 0"
        >
          <b-form-select
            v-model="form.branchId"
            :options="branches"
            :value="branches[0].Id"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Select supplier(s):"
          label-for="input-4"
          v-if="form.branchId == ''"
        >
          <b-form-select
            v-model="selected"
            :value="suppliers[0].Id"
            :options="suppliers"
            @change="selectedDistributor($event)"
          >
          </b-form-select>
        </b-form-group>
        <!-- Selected distributors -->
        <div class="selected-data add-user" v-if="!updatingUser">
          <div class="periods">
            <span
              v-for="supplier in selectedSuppliers"
              :key="supplier.Name"
              :title="supplier.Name"
            >
              <a href="#" class="supplier-name">{{ supplier.Name }}</a
              ><span class="remove" @click="removeReportPeriod(supplier.Id)"
                ><i class="far fa-trash-alt"></i
              ></span>
            </span>
          </div>
        </div>
        <b-button type="submit" variant="primary">
          Update user
        </b-button>
        <b-button @click="closeModal" type="button" variant="primary cancel"
          >Cancel</b-button
        >
      </b-form>
      <div class="success-message" v-if="loading && !editState">
        <spinner v-if="loading"></spinner>
      </div>
      <div class="success-message" v-if="editState">
        <div class="icon-wrapper">
          <i class="fas fa-check"></i>
        </div>
        <p>User updated successfully!</p>
        <b-button @click="closeUpdateModal" type="button" variant="primary"
          >Close</b-button
        >
      </div>
    </modal>
  </b-card>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Pagination
} from "element-ui";
import modal from "../../components/Modal.vue";
import config from "../../config";
import { userService } from "../../services/user.service";
import Spinner from "../../components/Spinner.vue";
import { sha256 } from "js-sha256";

export default {
  name: "users-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Pagination.name]: Pagination,
    modal,
    Spinner
  },
  data() {
    return {
      selected: 0,
      isActive: false,
      isFocused: false,
      currentPage: 1,
      pageSize: 10,
      selectedName: "",
      selectedId: "",
      isModalVisible: false,
      editUserModal: false,
      updatingUser: false,
      deleteUserModal: false,
      supplierIDs: [],
      selectedSuppliers: [],
      editState: false,
      tableData: [
        {
          Id: "0",
          Name: "Loading...",
          DateCreated: "2021-03-13T09:23:10.000Z",
          totalTransactions: 0,
          devices: 0
        }
      ],
      form: {
        EmailAddress: "",
        phoneNumber: "",
        Password: "",
        PasswordHash: "",
        Name: "",
        branchId: "",
        supplierId: 0
      },
      selectedBranch: 0,
      selectedSupplier: 0
    };
  },
  computed: {
    loading() {
      return this.$store.getters["users/loading"];
    },
    success() {
      return this.$store.getters["users/success"];
    },
    error() {
      return this.$store.getters["users/error"];
    },
    rows() {
      return this.tableData.length;
    },
    displayData() {
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
    isLoading() {
      return this.$store.getters["suppliers/isLoading"];
    },
    branches() {
      // Create options array with a default
      const options = [{ value: 0, text: "Please select a branch" }];
      // Get branches from store
      const branchesData = this.$store.getters["branches/branches"];
      // Make options property for select match API returned values
      if (branchesData.length >= 0) {
        branchesData.forEach(b => {
          const option = {};

          option.value = b.Id;
          option.text = b.Name;

          options.push(option);
        });
      }

      return options;
    },
    suppliers() {
      // Create options array with a default
      const options = [{ value: 0, text: "Please select a supplier" }];
      // Get suppliers from store
      const supplierData = this.$store.getters["suppliers/distributors"];
      // Make options property for select match API returned values
      if (supplierData.length >= 0) {
        supplierData.forEach(s => {
          const option = {};

          option.value = s.Id;
          option.text = s.Name;

          options.push(option);
        });
      }
      return options;
    }
  },
  methods: {
    closeUpdateModal() {
      console.log("State", this.$store);
      // close modal
      this.editUserModal = false;
      this.isModalVisible = false;
      // Set edit state property
      setTimeout(() => {
        // This will reset the state
        this.$store.dispatch("users/complete");
        // Reset edit state
        this.editState = false;
      }, 500);
      console.log("State", this.$store);
    },
    sortChange($event) {
      // Get sort change data
      const { prop, order } = $event;
      console.log($event);
      // Check for sort order and table data accordingly
      if (order == "ascending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] > b[prop] ? 1 : -1
        );
      }

      if (order == "descending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] < b[prop] ? 1 : -1
        );
      }
      console.log("Table data:", this.tableData);
    },
    showUserEditModal(userId) {
      // Set selected id for update state
      this.selectedId = userId;
      // get user data from the store and populate form fields
      const user = this.$store.state.users.users.find(
        user => user.Id.toString() === userId.toString()
      );
      console.log("User state:", user.supplierIds.split());
      // Populate form field values with
      if (user) {
        // Generate supplier data information
        this.form.Name = user.Name;
        (this.form.EmailAddress = user.EmailAddress),
          (this.form.PasswordHash = user.PasswordHash);
        this.form.phoneNumber = user.phoneNumber;
        this.form.branchId = user.branchId;
        this.form.supplierId = user.supplierId;
        this.editUserModal = true;

        if (user.supplierIds.includes(",")) {
          // Convert to array
          this.supplierIDs = user.supplierIds.split(",");
          // Generate supplier data
          this.generateSupplierData(
            JSON.parse(JSON.stringify(this.supplierIDs))
          );
        } else if (user.supplierIds.length == 0) {
          this.supplierIDs = [];
        } else {
          // Convert to array
          this.supplierIDs = user.supplierIds.split();
          // Generate supplier data
          this.generateSupplierData(
            JSON.parse(JSON.stringify(this.supplierIDs))
          );
        }
      }

      console.log("After state:", this.supplierIDs);
    },
    confirmDeleteUser() {
      // Dispath delete user action
      this.$store.dispatch("users/deleterUser", this.selectedId).then(() => {
        // Dispatch an action to refresh
        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { Authorization: this.$store.getters["accounts/token"] }
        });
        http.get("/user/").then(response => {
          this.tableData = response.data;
          this.$store.dispatch("users/updateUsers", response.data);
          this.handleCurrentChange(1);
        });
        // Close modal
        this.deleteUserModal = false;
      });
    },
    showModal() {
      // Ensure form is clear
      this.onReset();
      // Change updatingUser state to true
      this.updatingUser = false;
      this.isModalVisible = true;
    },
    closeModal() {
      // Close modal

      this.editUserModal = false;
      this.isModalVisible = false;
      // Reset form fields
      //this.onReset();
      setTimeout(() => {
        this.onReset();
      }, 500);
    },
    closeModalHandler() {
      this.isModalVisible = false;
      this.editUserModal = false;
      this.deleteUserModal = false;

      this.onReset();
      setTimeout(() => {
        this.$store.dispatch("users/complete");
      }, 500);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async onEditUserSubmit() {
      console.log("Password Hash:", this.form.PasswordHash);
      // user data to edit
      const user = {
        Id: this.selectedId,
        EmailAddress: this.form.EmailAddress,
        phoneNumber: this.form.phoneNumber,
        Name: this.form.Name,
        branchId: this.form.branchId,
        supplierIds: JSON.parse(JSON.stringify(this.supplierIDs))
      };
      this.form.Password !== ""
        ? (user.PasswordHash = sha256(this.form.Password))
        : (user.PasswordHash = this.form.PasswordHash);

      // Dispatch action for adding USER
      const result = await this.$store.dispatch("users/addUser", user);
      // Check and handle result
      if (result && result.data == "OK") {
        this.editState = true;
        setTimeout(async () => {
          // close modal
          //this.editUserModal = false;

          // Dispatch an action to refresh
          const updatedUsers = await this.$store.dispatch("users/getAllUsers");
          // Get suppliers again
          this.tableData = updatedUsers;
          // Reset form fields
          this.onReset();
          // Dispatch an action to refresh devices statev
          setTimeout(() => {
            this.$store.dispatch("users/complete");
          }, 500);
        }, 1500);
      }
    },
    async onSubmit() {
      // User form data
      const user = {
        EmailAddress: this.form.EmailAddress,
        phoneNumber: this.form.phoneNumber, 
        Name: this.form.Name,
        PasswordHash: sha256(this.form.Password),
        branchId: this.form.branchId,
        supplierIds: JSON.parse(JSON.stringify(this.supplierIDs))
      };
      // Dispatch action for adding USER
      const result = await this.$store.dispatch("users/addUser", user);
      // Check and handle result
      if (result && result.data == "OK") {
        setTimeout(async () => {
          // close modal
          this.isModalVisible = false;
          // Dispatch an action to refresh
          const updatedUsers = await this.$store.dispatch("users/getAllUsers");
          // Get suppliers again
          this.tableData = updatedUsers;
          // Reset form fields
          this.onReset();
          // Dispatch an action to refresh devices statev
          setTimeout(() => {
            this.$store.dispatch("users/complete");
          }, 500);
        }, 1500);
      }
    },
    validation() {
      let valid = false;

      if (
        this.form.fullName !== "" &&
        this.form.emailAddress !== "" &&
        this.form.password !== ""
      ) {
        valid = true;
      }

      return valid;
    },
    onReset() {
      // Resets form values
      this.form.Name = "";
      this.form.EmailAddress = "";
      this.form.phoneNumber = "";
      this.form.Password = "";
      this.form.branchId = 0;
      this.form.supplierId = 0;
      this.supplierIDs = [];
      this.selectedSuppliers = [];
    },
    update() {
      // Get form fields values via this.form
      // Send values to server
    },
    deleteUser(userId) {
      // Open confirmation modal
      this.deleteUserModal = true;
      // Set selected user value
      this.selectedId = userId;
    },
    filterTableBySearch($event) {
      // Get typed in value in input
      const query = $event.target.value.trimStart().toLowerCase();
      // Get pressed key code
      const pressKeyCode = $event.keyCode;
      // Store length of query
      const queryLength = query != "" ? query.length : 0;

      if (queryLength > 0) {
        // Show cancel icon
        this.isActive = true;
        // When the backspace key is pressed
        if (pressKeyCode == 8) {
          // Get value in input once again
          const value = $event.target.value.trimStart().toLowerCase();
          // Filter data
          this.filterTableData(value);
        }
        // Filter clients data
        this.filterTableData(query);
      } else {
        // Show cancel icon
        this.isActive = false;
        // Reset table data when there is no value in input
        this.resetTableData();
      }
    },
    filterTableData(query = "") {
      // Get all clients from store
      const data = this.$store.getters["users/users"];
      // Filter clients by search query
      const filterdClients = data.filter(
        c =>
          c.Name.toLowerCase()
            .replaceAll(" ")
            .startsWith(query) ||
          c.EmailAddress.toLowerCase()
            .replaceAll(" ")
            .startsWith(query) ||
          c.branchName
            .toLowerCase()
            .replaceAll(" ")
            .startsWith(query) ||
          c.Name.toLowerCase().includes(query) ||
          c.branchName.toLowerCase().includes(query) ||
          c.phoneNumber.toLowerCase().includes(query) ||
          c.EmailAddress.toLowerCase().includes(query)
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filterdClients;
    },
    resetTableData() {
      // When query length is zero input is in empty state, we get client from store
      const data = this.$store.getters["users/users"];
      // Store clients from store and update table data
      this.tableData = data;
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
    },
    /*
     * Selected Distributor
     */
    selectedDistributor($event) {
      // Store user ID
      const userID = $event;
      // Check if selected id has already been selected
      const idExists = this.supplierIDs.filter(i => i == userID);
      // Add id if its not already in the array
      if (idExists.length == 0) {
        // Push ID into supplier array
        this.supplierIDs.push(userID.toString());
        // Get suppliers from the store
        const supplierData = this.$store.getters["suppliers/distributors"];
        // Find supplier by ID from computed property
        const supplier = supplierData.find(s => s.Id == userID);
        // Add to clients
        if (supplier) {
          // Push into selectedSuppliers array
          this.selectedSuppliers.push({
            Id: supplier.Id,
            Name: supplier.Name
          });

          console.log(this.selectedSuppliers);
        }
      }
    },
    /*
     * Removes selected supplier
     */
    removeReportPeriod(id) {
      // Convert string to JSON
      const formattedReportPeriods = JSON.parse(
        JSON.stringify(this.selectedSuppliers)
      );
      // Get index of selected time
      const filteredArray = formattedReportPeriods.filter(p => p.Id !== id);
      // Remove from supplier ids
      this.supplierIDs = this.supplierIDs.filter(i => i != id);
      // Removed index array
      this.selectedSuppliers = filteredArray;
      // Show branch field
      if (this.supplierIDs.length == 0 && this.selectedSuppliers.length == 0)
        this.supplierIDs = [];
    },
    /*
     * Format supplier name
     */
    formatClientName(val) {
      return val != null ? val.split(" ")[0].trimEnd() + ".." : val;
    },
    /*
     * Generate suppliers IDs and supplier names when editing user
     */
    generateSupplierData(supplierIDs) {
      // Get and store supplier
      const supplierData = this.$store.getters["suppliers/distributors"];
      // Loop through array and create user object for selected suppliers
      if (supplierIDs.length > 0) {
        supplierIDs.forEach(id => {
          // Filter suppliers by current id
          const supplier = supplierData.find(sup => sup.Id.toString() == id);
          // Push user into selected supplier
          this.selectedSuppliers.push({
            Id: supplier.Id,
            Name: supplier.Name
          });
        });
      }
    }
  },
  mounted() {
    //this.isLoading = true;
    var http = this.axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: this.$store.getters["accounts/token"] }
    });
    http.get("/user/").then(response => {
      this.tableData = response.data;
      this.$store.dispatch("users/updateUsers", response.data);
      console.log("Users:", this.$store.state.users.users);
      console.log(this.tableData,"Table data");
      // Get suppliers & put them in store

      // Get branches & put them in store
      this.handleCurrentChange(1);
      //this.isLoading = false
    });

    // Dispatch branches
    this.$store.dispatch("branches/addBranches");

    // Dispatch supplier
    this.$store.dispatch("suppliers/getSuppliers");
  }
};
</script>
<style>
.buttons {
  text-align: center;
  margin-bottom: 10px;
}

.user-modal {
  display: none;
}

.cancel {
  color: #212529;
  background-color: #d2e3ee;
  border-color: #d2e3ee;
}

.cancel:hover {
  background-color: #d2e3ee;
  border-color: #d2e3ee;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  background: #f7941d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.icon-wrapper i {
  font-size: 30px;
  color: #fff;
}

.success-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}

.report-times {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
}

.report-times .title {
  color: #f7941d;
  font-size: 12px;
}

.devices {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  padding: 0 5px;
  border-bottom: 1px solid #ebeef5;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 35px;
}

.devices .device {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebeef5;
  padding: 10px 0;
}

.devices .device button {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f7941d;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.devices .device button i {
  color: #f7941d;
}

.error .message-icon {
  background: #f5f5f5;
}

.error .message-icon i {
  color: #f7941d;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
}

.message-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.message.delete {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.delete .message-icon {
  background: #f5f5f5;
}

.message.delete .message-icon i {
  color: #f7941d;
  margin-top: -4px;
}

.message-icon i {
  font-size: 30px;
}

.message span {
  color: #525f7f;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.form-control:focus,
.form-control:active {
  background: #fff !important;
}

.label {
  font-size: 12px;
}

.periods {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

.add-user .periods {
  margin-top: -10px;
  margin-bottom: 10px;
}

.add-user span {
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  display: flex;
  margin-right: 5px;
  margin-bottom: 7px;
}

.add-user span a {
  text-decoration: underline;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
}

.add-user span .remove {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 4px;
  color: #f7941d;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #f1f1f1;
  cursor: pointer;
}

.add-user span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
  font-size: 11px;
  margin-left: -3px;
}

.add-user .time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 4px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

span.time {
  background: #f7941d;
  border-radius: 25px;
  font-size: 12px;
  color: #fff !important;
  position: relative;
  max-width: 75px;
  display: flex;
  align-items: center;
  padding: 5px;
  font-weight: 600;
  display: flex;
  min-height: 30px;
  min-width: 75px;
  margin-right: 10px;

  margin-bottom: 7px;
}

span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
}

.time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 5px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
}

.time-error {
  color: #f7941d;
  font-size: 12px;
}

.form-control[readonly] {
  background-color: #fff !important;
}

#search-wrapper {
  position: relative;
  margin: 0;
}

#search-wrapper.focused input {
  border-color: #f7941d;
}

#search-wrapper.active .clear-search {
  display: flex;
}

#search-wrapper .clear-search {
  padding-top: 2px;
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}
#search-wrapper .clear-search i {
  font-size: 13px;
  line-height: none;
  padding: 0;
  margin: 0;
  color: #525f7f;
}
</style>
